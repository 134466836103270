function createPopupListItem(artist, clickCallback){
    // console.log(f);

    const li = document.createElement('li');
    li.className = 'popup-list__item';
    // active'

    const div1 = document.createElement('div');
    div1.className='popup-list__item__img';

    const img = document.createElement('img')
    img.className ='img--bg';
    img.src = artist.image;
    div1.appendChild(img);

    const div2 = document.createElement('div');
    div2.className = 'popup-list__item__content';

    const h4 = document.createElement('h4');
    h4.innerHTML = `${artist.name}`;

    div2.appendChild(h4);

    if(artist.birthdate){
        const p = document.createElement('p');
        p.innerHTML = artist.birthdate;
        div2.appendChild(p);
    }

    li.appendChild(div1);
    li.appendChild(div2);

    if(typeof clickCallback == 'function'){
        li.addEventListener('click', (e) => {
            clickCallback(artist);
        })
    }

    return li;
}

function createPopupEmpty(){
    const li = document.createElement('li');
    li.className = 'popup-list__item';

    const p = document.createElement('p');
    p.innerHTML = 'Žádné výsledky';

    li.appendChild(p);

    return li;
}

export function createPopupList(list, artists, clickCallback){
    list.innerHTML = '';
    if(artists.length === 0){
        list.append(createPopupEmpty());
    }else{
        list.append(...artists.map((artist, i) => createPopupListItem(artist, clickCallback)));
    }
}
