import 'element-remove';

import {normalize} from "./normalize";
import {createPopupList} from "./popup"

window.reviewTagging = () => {
    const artistsSelect = document.querySelector('#review-tagged-artists');
    if (!artistsSelect) return;

    const artists = Array.from(artistsSelect.querySelectorAll('option')).map((option) => (
        {
            id: option.value,
            name: option.innerText,
            normalized_name: normalize(option.innerText),
            image: option.dataset.image,
            birthdate: option.dataset.birthdate
        }
    ))

    const popup = document.querySelector('#review-tagging-popup');
    const popupList = popup.querySelector('.popup-list')

    const textarea = document.querySelector('#review-tagging-textarea');
    const lengthSpan = document.querySelector('#review-tagging-length');
    const pointer = document.querySelector('span.pointer');
    pointer.style = {
        display: 'none',
        width: '20px',
        height: '20px',
        background: 'red',
        position: 'absolute',
    }

    const mock = document.querySelector('#review-tagging-mock');
    const taStyles = getComputedStyle(textarea);

    mock.style.border = '1px solid black';
    mock.style.display = 'inline-block';
    mock.style.maxWidth = taStyles['width'];
    mock.style.wordWrap = taStyles['word-wrap']

    mock.style.paddingTop = taStyles['padding-top'];
    mock.style.paddingBottom = taStyles['padding-bottom'];
    mock.style.paddingLeft = taStyles['padding-left'];
    mock.style.paddingRight = taStyles['padding-right'];

    mock.style.fontFamily = taStyles['font-family'];
    mock.style.fontSize = taStyles['font-size'];
    mock.style.fontStyle = taStyles['font-style'];
    mock.style.fontWeight = taStyles['font-weight'];

    mock.style.lineHeight = taStyles['line-height'];
    mock.style.color = taStyles['color'];

    let tagging = false;
    let tagCursor = null;

    const options = Array.from(artistsSelect.querySelectorAll('option[selected]'));
    const taggedArtistIds = options.map((option) => option.value);
    let taggedArtists = artists.filter((artist) => (taggedArtistIds.indexOf(artist.id) >= 0))

    const thumbsContainer = document.querySelector('#review-tagged-artists-thumbs');

    console.log({thumbsContainer});

    let token = null;

    let lastLength = null;
    let alt6 = false;

    textarea.addEventListener('keyup', (e) => {
        const cursor = getCursor(e.target);

        if (alt6 && e.key == '4' && e.altKey || e.key == '@' || textarea.value[cursor - 1] === '@') {
            tagging = true;

            tagCursor = cursor - 1;
            token = ''

            setTimeout(() => {
                // console.log('milosko1', textarea.value[cursor - 2], textarea.value[cursor - 1], textarea.value[cursor], textarea.value[cursor + 1], textarea.value[cursor + 2]);

                if (textarea.value[tagCursor] !== '@' && textarea.value[tagCursor + 1] === '@') {
                    tagCursor = tagCursor + 1;
                }

                showPopup(tagCursor);
            }, 50)
        } else if (tagCursor !== null) {

            if (e.target.value[tagCursor] !== '@') {
                const offset = e.target.value.length - lastLength;


                if (tagCursor > 0 && e.target.value[tagCursor - offset] === '@') {
                    tagCursor -= offset;
                    if (tagging) showPopup(tagCursor);

                } else if (tagCursor <= e.target.value.length - offset && e.target.value[tagCursor + offset] === '@') {
                    tagCursor += offset;
                    if (tagging) showPopup(tagCursor);

                } else {
                    tagCursor = null;
                    tagging = false;
                    hidePopup();
                }
            }

            const firstSpaceAfterCursor = e.target.value.indexOf(' ', tagCursor);

            if (tagging && (firstSpaceAfterCursor >= 0 && cursor >= firstSpaceAfterCursor) || cursor <= tagCursor) {
                tagging = false;
                hidePopup();
            }

            if (!tagging && cursor > tagCursor && (firstSpaceAfterCursor < 0 || (firstSpaceAfterCursor >= 0 && cursor <= firstSpaceAfterCursor))) {
                tagging = true;
                // showPopup(tagCursor);
            }

            if (firstSpaceAfterCursor >= 0) {
                token = e.target.value.substr(tagCursor + 1, firstSpaceAfterCursor - tagCursor - 1);
            } else {
                token = e.target.value.substr(tagCursor + 1);
            }
        }

        lastLength = e.target.value.length;

        alt6 = e.key == '6' && e.altKey;

        if (tagging) {
            showPopup(tagCursor);
        }


        let change = false;
        taggedArtists.forEach((artist, i) => {
            const artistTagged = e.target.value.indexOf(`@${artist.name}`) >= 0
            // console.log('tagged check', artist.name, artistTagged);
            if (!artistTagged) {
                change = true;
                taggedArtists = taggedArtists.filter((a, j) => i !== j);
            }
        })

        if(change){
            tagCursor = null;
            selectTaggedOptions();
            createThumbInputs();
        }

        lengthSpan.innerHTML = `Zbývá ${ 600 - e.target.value.length } znaků`
    })

    textarea.addEventListener('click', (e) => {
        if (tagCursor === null) return;

        const cursor = getCursor(e.target);
        const firstSpaceAfterCursor = e.target.value.indexOf(' ', tagCursor);

        if (cursor < tagCursor || (firstSpaceAfterCursor >= 0 && cursor > firstSpaceAfterCursor)) {
            tagging = false;
            hidePopup();
        } else if (cursor > tagCursor && (firstSpaceAfterCursor < 0 || cursor < firstSpaceAfterCursor)) {
            tagging = true;
            showPopup(tagCursor);
        }
    })

    function getCursor(input) {
        const cursor = getCursorPos(input);

        if (cursor.start) return cursor.start;

        else return cursor;
    }

    function getCursorPos(input) {
        if ("selectionStart" in input && document.activeElement == input) {
            return {
                start: input.selectionStart,
                end: input.selectionEnd
            };
        } else if (input.createTextRange) {
            var sel = document.selection.createRange();
            if (sel.parentElement() === input) {
                var rng = input.createTextRange();
                rng.moveToBookmark(sel.getBookmark());
                for (var len = 0;
                     rng.compareEndPoints("EndToStart", rng) > 0;
                     rng.moveEnd("character", -1)) {
                    len++;
                }
                rng.setEndPoint("StartToStart", input.createTextRange());
                for (var pos = {start: 0, end: len};
                     rng.compareEndPoints("EndToStart", rng) > 0;
                     rng.moveEnd("character", -1)) {
                    pos.start++;
                    pos.end++;
                }
                return pos;
            }
        }
        return -1;
    }

    function getCursorCoords(cursor) {
        const {value} = textarea;
        // get all lines before cursor
        const linesBeforeCursor = value.substring(0, cursor).split('\n');


        let tmpCursor = cursor;
        while (!(/\s/.test(value[tmpCursor]) || /\n/.test(value[tmpCursor])) && tmpCursor < value.length) {
            tmpCursor++;
        }

        const cursorShift = tmpCursor - cursor;

        //console.log(cursorShift);


        const linesBeforeTmpCursor = value.substring(0, tmpCursor).split('\n');

        const linesBeforeCursorCount = linesBeforeCursor.length - 1;

        const filteredLinesBeforeCursor = linesBeforeCursor.filter((line, i) => (i < linesBeforeCursorCount))

        const valueOfLinesBeforeCursor = filteredLinesBeforeCursor.length > 1 ? filteredLinesBeforeCursor.join("<br/>") + '<br/>' : filteredLinesBeforeCursor.join("<br/>");

        const lineWithCursor = linesBeforeCursor[linesBeforeCursor.length - 1];
        const fullLineWithCursor = linesBeforeTmpCursor[linesBeforeTmpCursor.length - 1];


        // set pointer position
        // console.log({lineWithCursor, fullLineWithCursor});

        // return;

        mock.innerHTML = fullLineWithCursor;
        let lineWithCursorHeight = mock.offsetHeight;


        let wrappedLastLineLength = 0
        let wrappedLineLength = 1;
        let wrappedLines = 0

        let tmpLineWithCursor = fullLineWithCursor;
        let tmpLineWithCursorHeight = lineWithCursorHeight;

        //console.log({fullLineWithCursor});
        // return;

        let lastWhiteSpace = 0;

        do {
            //console.log('testujem medzeru', fullLineWithCursor[fullLineWithCursor.length - wrappedLineLength]);
            if (/\s/.test(fullLineWithCursor[fullLineWithCursor.length - wrappedLineLength])) {
                lastWhiteSpace = wrappedLineLength;
            }
            //console.log({wrappedLineLength, lastWhiteSpace});

            wrappedLineLength++;


            tmpLineWithCursor = fullLineWithCursor.substring(0, fullLineWithCursor.length - wrappedLineLength)

            mock.innerHTML = tmpLineWithCursor;
            tmpLineWithCursorHeight = mock.offsetHeight;

            // console.log(lineWithCursorHeight, tmpLineWithCursorHeight, lineWithCursorHeight > tmpLineWithCursorHeight);
            if (lineWithCursorHeight > tmpLineWithCursorHeight) {
                // console.log('wrap', tmpLineWithCursor, tmpLineWithCursorHeight);


                const restOfWord = fullLineWithCursor.substr(fullLineWithCursor.length - wrappedLineLength, wrappedLineLength - lastWhiteSpace);

                //console.log('idem pridavat zvysok slova', '`' + restOfWord + '`');


                //potrebujem pridat tolko whitespacov, kolko je v poslednom slove
                mock.innerHTML = (tmpLineWithCursor + restOfWord).replace(' ', '&nbsp;');
                const tmpLineWithCursorWithSpaceHeight = mock.offsetHeight;
                //console.log(tmpLineWithCursorWithSpaceHeight,tmpLineWithCursorHeight)


                if (tmpLineWithCursorWithSpaceHeight > tmpLineWithCursorHeight) {
                    //console.log('este by som nemal wrappovat');

                    // debugger;
                } else {
                    //console.log('wrapujem');
                    //console.log({wrappedLineLength});

                    // debugger;

                    if (wrappedLines === 0) {
                        wrappedLastLineLength = wrappedLineLength - restOfWord.length;
                    }
                    wrappedLines++;
                    lineWithCursorHeight = tmpLineWithCursorHeight;
                }

            }

            // debugger;
        } while (tmpLineWithCursor.length > 0);

        if (wrappedLines === 0) {
            // nemam viac riadkov
            // console.log('"' + lineWithCursor.replace(/\s/g, '&nbsp;') + '"');

            mock.innerHTML = valueOfLinesBeforeCursor;
            const top = (mock.offsetHeight - parseInt(taStyles['padding-bottom']));

            mock.innerHTML = lineWithCursor.replace(/\s/g, '&nbsp;');
            const left = (mock.offsetWidth - parseInt(taStyles['padding-right']));

            return {top, left};

        } else {
            // mam viac riadkov

            mock.innerHTML = lineWithCursor.substr(0, lineWithCursor.length - (wrappedLastLineLength + 1)).replace(' ', '&nbsp;');
            ;
            const top = (mock.offsetHeight - parseInt(taStyles['padding-bottom']));

            mock.innerHTML = lineWithCursor.substr(-1 * (wrappedLastLineLength - cursorShift)).replace(/\s/g, '&nbsp;');
            const left = (mock.offsetWidth - parseInt(taStyles['padding-right']));

            return {top, left};
        }
    }

    function showPopup(cursor) {
        // console.log('showing popup', cursor);


        const searchToken = normalize(token);
        let firstSpaceAfterCursor = textarea.value.indexOf(' ', tagCursor)
        if (firstSpaceAfterCursor < 0) firstSpaceAfterCursor = cursor;

        let filteredArtists = artists.filter((artist) => (!taggedArtists.includes(artist)))

        if (searchToken.length > 0) {
            filteredArtists = filteredArtists.filter((artist) => (
                artist.normalized_name.indexOf(searchToken) >= 0
            ));
        }

        // console.log(filteredArtists);

        createPopupList(popupList, filteredArtists, (artist) => {
            // console.log(textarea.value)
            firstSpaceAfterCursor = textarea.value.indexOf(' ', tagCursor)

            // console.log({firstSpaceAfterCursor});

            const firstPart = textarea.value.substr(0, tagCursor + 1);

            // console.log(firstSpaceAfterCursor < 0 ? tagCursor + 1 : firstSpaceAfterCursor,
            //     textarea.value.length - firstSpaceAfterCursor);
            //
            // console.log(firstSpaceAfterCursor, tagCursor, textarea.value.length);

            let secondPart = '';

            if (firstSpaceAfterCursor >= 0) {
                secondPart = textarea.value.substr(firstSpaceAfterCursor + 1, textarea.value.length - firstSpaceAfterCursor - 1);
            }

            // console.log({firstPart, secondPart});

            textarea.value = `${firstPart}${artist.name} ${secondPart}`;
            const event = new Event('change');
            textarea.dispatchEvent(event);

            const newCursor = tagCursor + 1 + artist.name.length + 1;
            textarea.focus();
            textarea.setSelectionRange(newCursor, newCursor);

            taggedArtists.push(artist);
            // console.log({taggedArtists});
            selectTaggedOptions();
            createThumbInputs();

            tagging = false;
            hidePopup();
        })

        const coords = getCursorCoords(cursor);

        popup.style.display = null

        // console.log('moving popup', coords, popup.clientHeight);

        popup.style.left = coords.left + 'px';
        popup.style.top = (coords.top - popup.clientHeight) + 'px';


    }

    function hidePopup() {
        popup.style.display = 'none';
    }

    function selectTaggedOptions() {
        const options = Array.from(artistsSelect.querySelectorAll('option'));
        const taggedArtistIds = taggedArtists.map((artist) => artist.id);

        // console.log(options, taggedArtistIds);

        options.forEach((option) => {
            option.selected = taggedArtistIds.includes(option.value);
        })

        // Create a new 'change' event
        const event = new Event('change');
        artistsSelect.dispatchEvent(event);
    }

    function createThumbInputs() {
        // console.log({artists});

        const taggedArtistIds = taggedArtists.map((artist) => artist.id);
        const existingThumbs = Array.from(thumbsContainer.querySelectorAll('.rating-thumbs__artists')).filter((thumbs) => {
            if (taggedArtistIds.indexOf(thumbs.dataset.artist) < 0) {
                thumbs.remove();

                return false;
            }
            return true;
        }).map((thumbs) => thumbs.dataset.artist);

        // console.log({existingThumbs});

        taggedArtists.forEach((artist) => {
            if (existingThumbs.indexOf(artist.id) < 0) {
                // console.log('create thumb', artist);

                const div = document.createElement('div');
                div.dataset.artist = artist.id;
                div.className = "rating-thumbs__artists";

                const p = document.createElement('p');
                p.className = "artists__name";
                p.innerHTML = artist.name;
                div.appendChild(p);

                const div2 = document.createElement('div');
                div2.className = "artists__thumbs";

                const label_like = document.createElement('label');
                label_like.htmlFor = `review-artist-rating-like-${artist.id}`;
                label_like.className = "container";

                const input_like = document.createElement('input');
                input_like.type = "radio";
                input_like.id = `review-artist-rating-like-${artist.id}`;
                input_like.name = `artist-ratings[${artist.id}]`;
                input_like.value = 1;
                input_like.checked = true;
                label_like.appendChild(input_like);

                const span_like = document.createElement('span');
                span_like.className = "checkmark like";
                span_like.innerHTML = '<i class="fas fa-thumbs-up"></i>';
                label_like.appendChild(span_like);

                div2.appendChild(label_like);

                const label_dislike = document.createElement('label');
                label_dislike.htmlFor = `review-artist-rating-dislike-${artist.id}`;
                label_dislike.className = "container";

                const input_dislike = document.createElement('input');
                input_dislike.type = "radio";
                input_dislike.id = `review-artist-rating-dislike-${artist.id}`;
                input_dislike.name = `artist-ratings[${artist.id}]`;
                input_dislike.value = 0;
                label_dislike.appendChild(input_dislike);

                const span_dislike = document.createElement('span');
                span_dislike.className = "checkmark dislike";
                span_dislike.innerHTML = '<i class="fas fa-thumbs-down"></i>';
                label_dislike.appendChild(span_dislike);

                div2.appendChild(label_dislike);

                div.appendChild(div2);




                //<label htmlFor="review-artist-rating-dislike-{{ $artist->id }}" className="container">
                // <input
                //         type="radio"
                //         id="review-artist-rating-dislike-{{ $artist->id }}"
                //         name="artist-ratings[{{ $artist->id }}]"
                //         value="-1"
                //         {{$artist->pivot->rating === -1 ? 'checked' : null}}>
                //         <span className="checkmark dislike"><i className="fas fa-thumbs-down"></i></span>
                // </label>

                thumbsContainer.appendChild(div);
            }
        });
    };

}









